.use-cases {
  padding: 100px 50px;
  background-color: #fff;
  text-align: center;
  color: #0a0a23;
}

.use-cases h2 {
  font-size: 2.5rem;
  margin-bottom: 80px;
  color: #0a0a23;
}

.use-cases-container {
  display: flex;
  max-width: var(--container-max);
  margin: 0 auto;
  gap: var(--space-6);
}

.steps-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--space-7);
}

.step-block {
  display: flex;
  align-items: flex-start;
  gap: var(--space-4);
  padding: var(--space-4);
  background: #0a0a23;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
}

.step-number {
  font-weight: bold;
  font-size: 1.5rem;
  color: #7d86f3;
}

.step-content {
  text-align: left;
}

.step-content h3 {
  margin: 0 0 var(--space-2) 0;
  font-size: 1.5rem;
  color: #7d86f3;
}

.step-content p {
  margin: 0;
  color: #fff;
  line-height: 1.5;
}

.demo-container {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-gif {
  width: 100%;
  max-width: 800px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-lg);
}

@media (max-width: 1024px) {
  .use-cases-container {
    flex-direction: column;
  }
  
  .demo-container {
    order: -1;
  }
}
