.about-us {
  padding: 100px 50px;
  background-color: #0a0a23;
  text-align: center;
  color: #fff;
}

.about-us h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.about-us-container {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.team-member {
  text-align: center;
}

.team-member img {
  width: 150px;
  height: 150px;
  border-radius: 10px; /* Square with rounded corners */
  margin-bottom: 20px;
}

.team-member h3 {
  font-size: 1.25rem;
}

.team-member p {
  font-size: 1rem;
  color: #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between text and LinkedIn icon */
}

/* LinkedIn icon styling */
.linkedin-icon {
  color: #0077b5; /* LinkedIn blue */
  font-size: 1.2rem; /* Icon size */
  transition: color 0.3s ease;
}

.linkedin-icon:hover {
  color: #fff; /* Change icon color on hover */
}
