* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #0a0a23;
    color: #fff;
    line-height: 1.6;
  }
  
  h1, h2, h3 {
    font-weight: 600;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  button {
    cursor: pointer;
  }
  
  .App {
    text-align: center;
  }

  html {
    scroll-behavior: smooth;
  }
  
  