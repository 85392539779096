.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #0a0a23;
}

.main-section .highlight {
  color: #7d86f3;
}

.logo {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 22px;
}

.logo img {
  width: 35px; /* Smaller logo size */
  height: auto;
  border-radius: 8px; /* Slightly rounded corners */
  margin-right: 10px; /* Space between logo and text */
}

.header ul {
  display: flex;
  list-style-type: none;
  gap: 25px;
}

.header a {
  color: #fff;
  font-size: 16px;
  text-decoration: none; /* Removes underline on links */
}

.header a:hover {
  color: #7d86f3; /* Optional hover effect */
}

.login-btn {
  padding: 10px 20px;
  background-color: #fff;
  color: #0a0a23;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #f0f0f0; /* Optional hover effect for button */
}

.get-started-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.get-started-content h2 {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: var(--space-4);
  text-align: center;
}

.get-started-content p {
  color: #b0b0b9;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 70px;
  text-align: center;
}
