:root {
    --primary-light: #3b82f6;
    --primary-hover: #2563eb;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --space-5: 1.5rem;
    --space-6: 2rem;
    --space-7: 2.5rem;
    --space-8: 3rem;
    --radius-md: 8px;
    --radius-lg: 12px;
    --shadow-lg: 0 4px 6px rgba(0, 0, 0, 0.1);
    --transition-normal: all 0.3s ease;
    --transition-fast: all 0.2s ease;
  }
  
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #0a0a23;
    color: #ffffff;
  }
  
  .login-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a0a23;
    padding: var(--space-6);
  }
  
  .login-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #2c2c3c;
    border: 1px solid #4b4b63;
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-lg);
    padding: var(--space-6);
    max-width: 400px;
    width: 100%;
  }
  
  .login-box h1 {
    color: #ffffff;
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: var(--space-5);
  }
  
  .input-group {
    width: 100%;
    margin-bottom: var(--space-4);
    padding: 0 var(--space-2);
  }
  
  /* Add a specific margin for the password field */
  .input-group:last-of-type {
    margin-bottom: var(--space-8); /* Increase space below password input */
  }  
  
  .input-group label {
    display: block;
    font-size: 1rem;
    color: #b0b0b9;
    margin-bottom: var(--space-2);
  }
  
  .text-input {
    background-color: #1e1e2d;
    border: 1px solid #4b4b63;
    border-radius: var(--radius-md);
    color: #fff;
    font-size: 1rem;
    padding: var(--space-3);
    width: 100%;
    min-width: 320px;
    transition: var(--transition-normal);
  }
  
  .text-input:focus {
    border-color: var(--primary-light);
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3);
  }
  
  .login-btn {
    width: 100%; /* Keep full width */
    max-width: 200px; /* Limit the maximum width to make it more horizontal */
    padding: var(--space-2) var(--space-4); /* Reduce vertical padding, increase horizontal padding */
    background-color: var(--primary-light);
    color: #ffffff;
    border: none;
    border-radius: var(--radius-md);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition-normal);
    margin: 0 auto; /* Center align the button */
  }
  
  .login-btn:hover {
    background-color: var(--primary-hover);
    transform: translateY(-1px);
  }
  
  .home-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 8px 16px;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 4px;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s ease;
  }
  
  .home-btn:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  