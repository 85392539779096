.main-section {
  padding: 100px 50px;
  text-align: center;
  color: #fff;
}

.main-section h1 {
  font-size: 3rem;
  font-weight: 700;
}

.main-section .highlight {
  color: #7d86f3;
}

.main-section p {
  margin: 20px 0;
  font-size: 1.5rem;
  line-height: 1.4;
}

.request-demo-btn {
  margin: 40px 0;
  padding: 15px 30px;
  background-color: #fff;
  color: #0a0a23;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.partners {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.partners img {
  width: 100px;
  height: auto;
}

.stat-blocks {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 60px auto;
  max-width: 1400px;
  padding: 0 20px;
}

.stat-block {
  background: rgba(125, 134, 243, 0.1);
  border: 1px solid rgba(125, 134, 243, 0.2);
  border-radius: 12px;
  padding: 25px;
  width: 320px;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.stat-block:hover {
  transform: translateY(-5px);
  border-color: #7d86f3;
}

.stat-number {
  color: #7d86f3;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.stat-label {
  color: #fff;
  font-size: 1rem;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .stat-blocks {
    flex-direction: column;
    align-items: center;
  }

  .stat-block {
    width: 100%;
    max-width: 280px;
  }
}

