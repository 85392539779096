.footer {
  padding: 10px;
  background-color: #101024;
  text-align: center;
  color: hsl(0, 0%, 75%);
  border-top: 0.5px solid #fff; /* Thinner white border above the footer */
}

.footer p {
  font-size: 0.875rem;
}
