.dashboard-container {
    display: flex;
    min-height: 100vh;
    background-color: #1e1e2d;
  }
  
  .dashboard-content {
    flex: 1;
    margin-left: 250px;
    padding: var(--space-6);
    color: #ffffff;
  }
  
  .page-header {
    margin-bottom: var(--space-6);
  }
  
  .page-header h1 {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: var(--space-3);
  }
  
  .page-header p {
    color: #b0b0b9;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    gap: var(--space-3);
  }
  
  .user-email {
    color: #b0b0b9;
  }
  
  .logout-btn {
    background-color: transparent;
    border: 1px solid #4b4b63;
    color: #ffffff;
    padding: var(--space-2) var(--space-4);
    border-radius: var(--radius-md);
    transition: var(--transition-normal);
  }
  
  .logout-btn:hover {
    background-color: #3b3b4f;
  }

  .model-details-tabs {
    display: flex;
    gap: var(--space-4);
    margin-bottom: var(--space-5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: var(--space-2);
  }
  
  .model-details-tabs button {
    background: none;
    border: none;
    color: var(--text-light);
    font-size: 1.1rem;
    padding: var(--space-2) var(--space-3);
    cursor: pointer;
    transition: var(--transition-normal);
    position: relative;
  }
  
  .model-details-tabs button:hover {
    color: #ffffff;
  }
  
  .model-details-tabs button.active {
    color: #ffffff;
  }
  
  .model-details-tabs button.active::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--primary-light);
  }
  
  .details-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: var(--radius-lg);
    padding: var(--space-5);
    margin-bottom: var(--space-4);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--space-4);
    margin-top: var(--space-4);
  }
  
  .info-item {
    padding: var(--space-3);
    background: rgba(255, 255, 255, 0.03);
    border-radius: var(--radius-md);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .info-item label {
    color: var(--text-light);
    font-size: 0.9rem;
    margin-bottom: var(--space-2);
    display: block;
  }
  
  .info-item span {
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  .code-block {
    background: rgba(0, 0, 0, 0.3);
    border-radius: var(--radius-md);
    padding: var(--space-4);
    position: relative;
    margin-top: var(--space-4);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .code-block pre {
    color: #ffffff;
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', monospace;
    font-size: 0.9rem;
    line-height: 1.5;
    overflow-x: auto;
  }
  
  .code-block button {
    position: absolute;
    top: var(--space-3);
    right: var(--space-3);
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #ffffff;
    padding: var(--space-2) var(--space-3);
    border-radius: var(--radius-sm);
    cursor: pointer;
    transition: var(--transition-normal);
  }
  
  .code-block button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .weights-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: var(--space-4);
    margin-top: var(--space-4);
  }
  
  .weight-format {
    background: rgba(255, 255, 255, 0.03);
    border-radius: var(--radius-md);
    padding: var(--space-4);
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
  }
  
  .weight-format h3 {
    color: #ffffff;
    margin-bottom: var(--space-3);
    font-size: 1.1rem;
  }
  
  .download-btn {
    background: var(--primary-light);
    color: #ffffff;
    border: none;
    padding: var(--space-2) var(--space-4);
    border-radius: var(--radius-md);
    cursor: pointer;
    transition: var(--transition-normal);
    width: 100%;
  }
  
  .download-btn:hover {
    background: var(--primary-hover);
  }
  
  .model-status {
    display: inline-flex;
    align-items: center;
    padding: var(--space-1) var(--space-3);
    border-radius: var(--radius-md);
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .model-status.deployed {
    background: rgba(40, 167, 69, 0.2);
    color: #28a745;
  }
  
  .page-header {
    margin-bottom: var(--space-5);
  }
  
  .page-header h1 {
    color: #ffffff;
    margin-bottom: var(--space-2);
  }