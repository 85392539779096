.blog {
    padding: 60px 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    color: #333;
  }
  
  .demo-content {
    flex: 1;
    max-width: 45%;
    text-align: left;
  }
  
  .demo-content h2 {
    font-size: 2.5rem;
    margin-bottom: var(--space-3);
    color: var(--primary);
    text-align: left;
  }
  
  .demo-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text-light);
    margin-bottom: var(--space-4);
    text-align: left;
  }
  
  .demo-form-container {
    flex: 1;
    max-width: 45%;
  }
  
  .demo-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
    padding: var(--space-6);
    background: #f8f9fa;
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
  }
  
  .form-group {
    width: 100%;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: var(--space-3);
    border: 1px solid #4b4b63;
    border-radius: var(--radius-md);
    font-size: 1rem;
    background: #fff;
    transition: var(--transition-normal);
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: var(--primary-light);
    box-shadow: 0 0 0 2px rgba(125, 134, 243, 0.1);
  }
  
  .demo-submit {
    background: var(--primary-light);
    color: #fff;
    border: none;
    padding: var(--space-3) var(--space-4);
    border-radius: var(--radius-md);
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition-normal);
  }
  
  .demo-submit:hover {
    background: var(--primary-hover);
    transform: translateY(-1px);
  }
  
  @media (max-width: 768px) {
    .blog {
      flex-direction: column;
      gap: 2rem;
    }
    
    .demo-content,
    .demo-form-container {
      max-width: 100%;
    }
    
    .demo-content h2 {
      font-size: 2rem;
    }
    
    .demo-form {
      padding: var(--space-4);
    }
  }
  
  .footer-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 40px 50px;
    background-color: #fff;
    margin-top: 0;
  }
  
  .company-info {
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  
  .company-logo {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  
  .company-details {
    display: flex;
    flex-direction: column;
    color: #333;
    text-align: left;
    align-items: flex-start;
  }
  
  .company-name {
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
  }
  
  .company-location {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.2;
    text-align: left;
  }
  
  .partners-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
  }
  
  .partners-title {
    color: #666;
    font-size: 0.9rem;
    text-align: center;
    width: 100%;
  }
  
  .partner-logos {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  
  .partner-logo {
    height: 40px;
    width: auto;
    opacity: 0.8;
    transition: opacity 0.2s ease;
  }
  
  .partner-logo:hover {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .footer-section {
      flex-direction: column;
      gap: 30px;
      text-align: center;
    }
    
    .company-info {
      justify-content: center;
    }
    
    .partners-section {
      align-items: center;
    }
  }
  
  .nvidia-logo {
    height: 90px;
  }
  
  .social-links {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #eee;
    width: 100%;
  }
  
  .follow-text {
    font-size: 0.9rem;
    color: #666;
  }
  
  .social-icon {
    width: 18px;
    height: 18px;
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }
  
  .social-icon:hover {
    opacity: 1;
  }
  
  .submit-message {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 4px;
    text-align: center;
  }
  
  .submit-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .submit-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .demo-submit:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
