.get-started {
  background-color: #0a0a23;
  color: #fff;
  padding: 50px 50px;
}

.get-started .highlight {
  color: #7d86f3;
}

.get-started-content {
  text-align: right;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.get-started-content h2 {
  font-size: 2.5rem;
  margin-bottom: var(--space-4);
  color: #fff;
}

.get-started-content p {
  font-size: 1.25rem;
  color: #b0b0b9;
  line-height: 1.5;
  margin-bottom: 70px;
}

.models-grid {
  display: grid;
  grid-template-columns: repeat(5, 260px);
  gap: 60px 50px;
  margin-top: 60px;
  justify-content: center;
}

.model-box {
  background: #fff;
  border-radius: 12px;
  padding: 25px;
  text-align: center;
  transition: transform 0.2s ease;
  width: 280px;
  margin-bottom: 60px;
}

.model-box:hover {
  transform: translateY(-5px);
}

.model-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  object-fit: contain;
}

.model-box h3 {
  color: #0a0a23;
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.model-box span {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 1200px) {
  .models-grid {
    grid-template-columns: repeat(3, 260px);
    gap: 60px 30px;
  }
}

@media (max-width: 768px) {
  .models-grid {
    grid-template-columns: repeat(2, 260px);
    gap: 60px 25px;
  }
  
  .get-started-content h2 {
    font-size: 2rem;
  }
  
  .get-started-content p {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .models-grid {
    grid-template-columns: 260px;
    gap: 60px;
  }
}
  
